import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AppLayoutComponent } from "./layout/app.layout.component";

const routes: Routes = [ 
  {
    path: '', component: AppLayoutComponent,
    children: [
        { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },       
        { path: 'components', loadChildren: () => import('./components/components.module').then(m => m.UIComponentsModule) },                
    ]
  },  
  { path: '**', redirectTo: '/notfound' },         
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
