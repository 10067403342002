<div class="layout-topbar">
    <a routerLink="" (click)="hidesidebar_isnotAuthenticated()">                  
        <img src="assets/images/logo-ship-text.svg" alt="logo" width="230" height="100">            
    </a>  
    <!-- Buttons displayed when menu mobile is active -->
    <div *ngIf="auth.isAuthenticated$ | async; else other_content"> 
        <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
            <i lass="pi pi-ellipsis-v"></i> 
        </button>   
    </div>  
    <ng-template #other_content>
        <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button">
            <i class="pi pi-user mr-4 mb-3 p-text-secondary" (click)="loginWithRedirect();" style="font-size: 2rem" pTooltip="Login" tooltipPosition="bottom"></i> 
        </button>       
    </ng-template>            
    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <div *ngIf="auth.isAuthenticated$ | async">
            <div class="flex flex-wrap gap-2">
                <i class="pi pi-angle-double-right mr-4 mb-3 p-text-secondary" 
                (click)="this.layoutService.onMenuToggle()" style="font-size: 2rem" 
                pTooltip="Show/hide sidebar" tooltipPosition="bottom"></i>
            </div> 
        </div>
        <div *ngIf="auth.isAuthenticated$ | async">
            <div class="flex flex-wrap gap-2">
                <i class="pi pi-folder mr-4 mb-3 p-text-secondary" 
                [routerLink]="['/components/projects']" style="font-size: 2rem" 
                pTooltip="Edit projects" tooltipPosition="bottom"></i>
            </div> 
        </div>
        <div *ngIf="auth.isAuthenticated$ | async">          
            <div *ngIf="this.layoutService.numbernotifications==0; else new_notifications" class="flex flex-wrap gap-2">
                <i class="pi pi-bell mr-4 mb-3 p-text-secondary" style="font-size: 2rem" (click)="isBellEnabled();op1.toggle($event)" 
                pTooltip="Notifications" tooltipPosition="bottom"></i>
            </div>
            <ng-template #new_notifications>  
                <i class="pi pi-bell mr-4 mb-3 p-text-secondary" pBadge style="font-size: 2rem" value="{{this.layoutService.numbernotifications}}" 
                (click)="isBellEnabled();op1.toggle($event)" pTooltip="Notifications" tooltipPosition="bottom"></i>
            </ng-template>            
            <p-overlayPanel #op1>               
                <div *ngIf="auth.user$ | async as user">                                           
                    <li class="flex align-items-center gap-2">
                        <span class="font-medium text-900 block">Notifications</span>                                              
                        <button pButton style="border-radius: 5px;" class="flex align-items-center gap-2 text-color-secondary ml-auto text-sm text-blue-600 p-button-text" (click)="op1.toggle($event)" >                                   
                            <i class="pi pi-times"></i>                                
                        </button> 
                    </li>                        
                </div>
                <app-notifications></app-notifications>	                           
            </p-overlayPanel>          
        </div>        
        <div *ngIf="(auth.isAuthenticated$ | async) === false">
            <!-- Login button: show if NOT authenticated --> 
            <div class="flex flex-wrap gap-2">
                <i class="pi pi-user mr-4 mb-3 p-text-secondary" (click)="loginWithRedirect();" style="font-size: 2rem" pTooltip="Login" tooltipPosition="bottom"></i>
            </div>                        
        </div> 
        <div *ngIf="auth.user$ | async as user" class="flex align-items-center">   
            <div>
                <p-avatar [image]="user.picture" styleClass="mr-2 mb-3" size="small" shape="circle" (click)="op.toggle($event)" pTooltip="Account" tooltipPosition="bottom"></p-avatar>   
            </div>        
            <p-overlayPanel #op>
                <div class="flex flex-column gap-3 w-25rem">
                    <div *ngIf="auth.user$ | async as user">                                                 
                        <li class="flex align-items-center gap-2">
                            <span class="font-medium text-900 block">My account</span>                        
                            <button pButton style="border-radius: 5px;" class="flex align-items-center gap-2 text-color-secondary ml-auto text-sm text-blue-600 p-button-text" (click)="op.toggle($event)" >                                   
                                <i class="pi pi-times"></i>                                
                            </button>
                        </li>                       
                        <ul class="list-none p-0 m-0 flex flex-column gap-3">
                            <li class="flex align-items-center gap-2">                                
                                <p-avatar [image]="user.picture" styleClass="mr-2" size="small" shape="circle"></p-avatar>
                                <div>
                                    <span class="font-medium">{{ layoutService.name }}</span>
                                    <div class="text-sm text-color-secondary">{{ layoutService.email }}</div>
                                </div>                               
                            </li>
                        </ul>                    
                    </div> 
                    <div>
                        <p-button label="My profile" icon="pi pi-cog" styleClass="p-button-link text-blue-600" (click)="op.toggle($event)" [routerLink]="'components/profile'"></p-button>                          
                    </div>                              
                    <div>                                             
                        <button pButton style="border-radius: 5px;" pRipple type="button" icon="pi pi-sign-out" label="Sign out" [routerLink]="['/']" (click)="logout()" class="mr-3 p-button-outlined text-blue-600 p-2"></button>
                        <button pButton style="border-radius: 5px;" pRipple type="button" icon="pi pi-times" label="Cancel" (click)="op.toggle($event)" class="p-button-outlined text-blue-600 p-2"></button>
                    </div>                    
                </div>                
            </p-overlayPanel>     
        </div>        
    </div>
</div>







