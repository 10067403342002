import { Component, ElementRef, ViewChild, Inject, OnInit } from '@angular/core';
import { LayoutService } from "./service/app.layout.service";
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { NotificationService } from '../components/notifications/notification.service';
import { UserService } from '../components/users/user.service';
import { ContextMenu } from 'primeng/contextmenu';
import { ProjectService } from 'src/app/components/projects/project.service';
import { Router } from '@angular/router';

interface Order {
    ordername: string;
    code: string;
}

interface OrderBy {
    name: string;
    code: string;
}

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',   
})

export class AppTopBarComponent implements OnInit {    
    orderList: Order[] | undefined;
    orderbyList: OrderBy[] | undefined;
    selectedOrder: Order | undefined; 
    sortorder: string = '';  
    orderby: string = '';
    selectedOrderby: OrderBy | undefined;     
    read_unread: string ='';    
    isCollapsed = true;
    visible: boolean = false; 
    visible_create_new = false;
    visible_delete: boolean = false;   
    token: any;    
    auth_user_id: string;
    users: string;
    server_id: string;
    email: string;   
    key_value: string;    
    notification: string;
    notificationsAssigned: string[];      
    projectsOpened = []; 
    title: string;
    appOrigin: string;
    expirationAt: string;
    authorId: number;
    assignedTo: string[] = [];
    link: string;
    description: string;
    read: boolean = false;
    createdAt_date: string;
    expirationAt_date: string;
    new_notification: string | undefined;    
    user_from_compass: boolean = false; 
    ids_notifications: string[] = [];  
    display: boolean = false;  
    users_assigned: any[];     
    selectedItem: any = null;
    timer: number;
    list_ids_notifications: string | undefined;
    users_assigned_names: string[] = [];
    projects: any[] | undefined;
    accessToken: string | null = null;
    read_unred: string = '';

    @ViewChild('cm') cm: ContextMenu;

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(public layoutService: LayoutService, 
        public notificationService : NotificationService,         
        public projectService: ProjectService,               
        public auth: AuthService,       
        public userService : UserService,
        private router: Router,     
        @Inject(DOCUMENT) private doc: Document
        ) { 
           
    } 

    ngOnInit() {  
        this.auth.user$.subscribe(
            (profile) => {
                if (profile != null) {                                       
                    // console.log('profile',profile);
                    this.is_user_from_compass(profile.email);                       
                    this.auth_user_id = profile.sub;                    
                    this.getMyHubId();                      
                }
            }
        );  

        this.hidesidebar_login();    
                   
        this.orderList = [
            { ordername: 'Ascending', code: 'ASC' },
            { ordername: 'Descending', code: 'DESC' }
        ];

        this.orderbyList = [
            { name: 'Title', code: 'title' },           
            { name: 'Author', code: 'author' },
            { name: 'Expiration At', code: 'expirationAt' },
            { name: 'Created At', code: 'createdAt' }
        ];          
    } 

    visit_web_site() {        
        return this.selectedItem.link;    
    } 
    
    is_user_from_compass(email) {
        let result = email.indexOf("@");
        let res = email.substring(result);
        if (res == "@compassis.com") {
            this.user_from_compass = true;
            this.layoutService.is_user_from_compass = true;                 
        } else {
            this.user_from_compass = false;
            this.layoutService.is_user_from_compass = true;                 
        }    
    }

    isBellEnabled() {
        if (this.layoutService.notifications_from_dashboard = true) {
            // Go to Dashboard
            this.router.navigate(['/']); 
        }
        this.layoutService.is_bell = true;
    }
    
    async loginWithRedirect() {      
        this.auth.loginWithRedirect();                     
    } 
    
    logout() {                                  
        this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });                     
    }

    hidesidebar_login() {  
        if (this.layoutService.isDesktop()) {     
            if (this.layoutService.state.staticMenuDesktopInactive == false) {                                   
                this.layoutService.onMenuToggle();                                          
            }; 
        } else {
            if (this.layoutService.state.staticMenuMobileActive == false) {
                this.layoutService.onMenuToggle();   
            }
        }    
    }
    
    getMyHubId() {
        this.auth.getAccessTokenSilently().subscribe(
            token => {  
                this.accessToken = token;
                // console.log('Access Token:', this.accessToken);
                this.userService.getMyHubId(this.auth_user_id,this.accessToken).subscribe(
                    server_user_id => {    
                        console.log('server_user_id getmyHubId',server_user_id);                      
                        this.layoutService.existed = server_user_id.existed; 
                        this.layoutService.hub_id = server_user_id.hub_id;   

                        this.layoutService.notificationList = server_user_id.notificationsAssigned;
                        
                        this.layoutService.projectsnumber = server_user_id.numAllProjects;
                        this.layoutService.openedprojectsnumber = server_user_id.numOpenedProjects;

                        this.layoutService.deliverynotesnumber = server_user_id.numDeliveryNotes;                                               
                        this.layoutService.openeddeliverynotesnumber = server_user_id.numOpenedDeliveryNotes;
                        
                        this.layoutService.numbernotifications = this.layoutService.notificationList.length;
                        this.layoutService.readnotifications = server_user_id.numReadNotifications;

                        this.layoutService.inspectionsnumber = server_user_id.numInspectionsLastOpenedProject;
                        this.layoutService.inspectionsplannednumber = server_user_id.numInspectionsPlannedLastOpenedProject;                                                                                                        

                        this.layoutService.permissions = server_user_id.permissions;  
                        this.layoutService.projectsOpened = server_user_id.projectsOpened; 
                        console.log('projects',this.layoutService.projectsOpened);
                        console.log('user',server_user_id.user);                                               
                        this.layoutService.blocked = server_user_id.user.blocked;                      
                        this.layoutService.email = server_user_id.user.email;
                        this.layoutService.email_notifications_enabled = server_user_id.user.email_notifications_enabled;
                        this.layoutService.firstname = server_user_id.user.firstname;
                        this.layoutService.has_signature = server_user_id.user.has_signature;
                        this.layoutService.lastname = server_user_id.user.lastname;
                        this.layoutService.name = server_user_id.user.name;
                        this.layoutService.role = server_user_id.user.role;
                        this.layoutService.telephone = server_user_id.user.telephone;
                                                    
                        // this.getActiveUser(); 
                        // this.getUserRoles();   
                        // this.getUserPermissions();   
                        // this.getAllActiveUsers(); 
                        this.orderby = "orderBy=createdAt" 
                        this.sortorder = "?orderDir=DESC"   
                        this.getNotificationsAssignedToUser();                                                                                 
                        setInterval(() => this.getNotificationsAssignedToUser(), 60000);                            
                }), (error: any) => {
                    console.error(error);
                };
        }), (error: any) => {
            console.error(error);
        }; 
    }
    
    getFormattedDateTime() {
        const now = new Date();
        return now.toISOString();
    }

    removeOneDayToDate(date) {
        date.setDate(date.getDate() - 1)      
        return date
    }

    convertUTCToLocalTime(utcDateStr) {        
        const utcDate = new Date(utcDateStr);              
        const localTime = utcDate.toLocaleString();
        return localTime;
    }

    getTimeNotification(createdAt) {        
        let localtime_createdAt = this.convertUTCToLocalTime(createdAt);              
        let today = new Date();
        let today_date = today.toISOString().split('T')[0];            
        let yesterday = this.removeOneDayToDate(today);
        let yesterday_date = yesterday.toISOString().split('T')[0];                    
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];      
        if (createdAt.includes(yesterday_date)) {
            return 'Yesterday, ' + localtime_createdAt;
        } else if (createdAt.includes(today_date)) {
            return 'Today, ' + localtime_createdAt;
        } else {
            // Get the day of a specific date:
            let d = new Date(createdAt)
            let createdAt_day = weekday[d.getDay()] + "," + " " + localtime_createdAt;
            return createdAt_day;
        }
    } 
    getNotificationsAssignedToUser() {
        this.notificationService.getNotificationsAssignedToUser(this.layoutService.hub_id,this.sortorder,this.orderby,this.read_unread).subscribe(
            notificationArray => {   
                this.layoutService.numbernotifications = 0;  
                this.layoutService.readnotifications = 0;           
                this.layoutService.notificationList = notificationArray;  
                console.log('this.layoutService.notificationList',this.layoutService.notificationList);
                for (var i = 0; i < this.layoutService.notificationList.length; i++) {   
                    if (this.layoutService.notificationList[i].createdAt != null) {                                                    
                        this.layoutService.notificationList[i].date = this.getTimeNotification(this.layoutService.notificationList[i].createdAt); 
                        this.layoutService.notificationList[i].date = "";
                    } else {
                        this.layoutService.notificationList[i].date = "";
                    }
                    if (this.layoutService.notificationList[i].read == false) {
                        this.layoutService.numbernotifications++;
                    } else {
                        this.layoutService.readnotifications++;
                    }                   
                }                  
        }), (error: any) => {
            console.error(error);
        }; 
    }       

    hidesidebar_isnotAuthenticated() {       
        this.auth.isAuthenticated$.subscribe(
              (isAuthenticated) => {                
                if(isAuthenticated == false && this.layoutService.state.staticMenuDesktopInactive == false) {                                   
                    this.layoutService.onMenuToggle();                                          
                }             
            }
        );      
    }

    // getActiveUser() {
    //     this.userService.getActiveUser(this.layoutService.hub_id).subscribe(
    //         profile_user_id => {  
    //             console.log('profile_user',profile_user_id);                                
    //     }), (error: any) => {
    //         console.error(error);
    //     };  
    // }

    // getUserRoles() {
    //     this.userService.getUserRoles(this.layoutService.hub_id).subscribe(
    //         user_roles => {
    //             console.log('getUserRoles',user_roles);    
    //             });
    //     }), (error: any) => {
    //         console.error(error);
    //     };
    // }

    // getUserPermissions() {
    //     this.userService.getUserPermissions(this.layoutService.hub_id).subscribe(
    //         user_permissions => {
    //             console.log('getUserPermissions',user_permissions);                
    //     }), (error: any) => {
    //         console.error(error);
    //     };
    // }

    // getAllActiveUsers() {
    //     this.userService.getAllActiveUsers(this.token).subscribe((users) => {
    //         console.log('getAllActiveUsers',users);
    //     }), (error: any) => {
    //         console.error(error);
    //     };
    // }
}






