import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { NotificationService } from './notification.service';
import { Notification } from './notification';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { AuthService } from '@auth0/auth0-angular';
import { LayoutService } from "../../../app/layout/service/app.layout.service";
import { SelectItem } from 'primeng/api';

interface Order {
    ordername: string;
    code: string;
}

interface OrderBy {
    name: string;
    code: string;
}

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {

    selectedItem: any = null;
    cols: any[];
    items_menu!: MenuItem[];
    selectionKeys = {};
    notificationArray: Notification[] = [];
    responseJson: string;    
    token: any;
    id: number;
    apiUri: string | undefined;
    appUri: string | undefined;
    hub_id : number;
    sortorder: string = '';  
    orderby: string = '';
    read_unread: string =''; 
    orderList: Order[] | undefined;
    orderbyList: OrderBy[] | undefined;
    selectedOrder: Order | undefined; 
    selectedOrderby: OrderBy | undefined;      
    visible: boolean = false; 
    visible_create_new = false;
    visible_delete: boolean = false; 
    title: string; 
    description: string;
    read: boolean = false;
    createdAt_date: string;
    expirationAt_date: string;
    users_assigned_names: string[] = [];
    user_from_compass: boolean = false; 
    new_notification: string | undefined;    
    ids_notifications: number[] = []; 
    list_ids_notifications: string | undefined; 
    appOrigin: string;
    expirationAt: string;
    authorId: number;
    assignedTo: string[] = [];
    link: string;
    users_assigned: any[];  
    sortOptions: SelectItem[] = [];   

    constructor(public layoutService: LayoutService,
        public notificationService : NotificationService, 
        private configauth: AuthClientConfig, 
        public auth: AuthService) {          
    }

    ngOnInit() {          
        this.orderList = [
            { ordername: 'Ascending', code: 'ASC' },
            { ordername: 'Descending', code: 'DESC' }
        ];

        this.orderbyList = [
            { name: 'Title', code: 'title' },           
            { name: 'Author', code: 'author' },
            { name: 'Expiration At', code: 'expirationAt' },
            { name: 'Created At', code: 'createdAt' }
        ]; 
        this.orderby = "orderBy=createdAt" 
        this.sortorder = "?orderDir=DESC"                                                          
      
        this.user_from_compass = this.layoutService.is_user_from_compass;
        this.getNotificationsInterval();    
        setInterval(() => this.getNotificationsInterval(), 60000);  
    }   

    getClass(): string {
        if (this.layoutService.is_bell) {
            return "surface-card";
        } else {
            return "card";
        }
    }

    getClass_parent(): string {
        if (this.layoutService.is_bell) {
            return "col-12 surface-card";
        } else {
            return "col-12 mt-2";
        }
    }

    getstyle() {
        
    }

    isBellEnabled(): boolean {
        return !this.layoutService.is_bell;
      }

    getFormattedDateTime() {
        const now = new Date();
        return now.toISOString();
    }

    getTimeNotification(createdAt) {        
        let localtime_createdAt = this.convertUTCToLocalTime(createdAt);              
        let today = new Date();
        let today_date = today.toISOString().split('T')[0];            
        let yesterday = this.removeOneDayToDate(today);
        let yesterday_date = yesterday.toISOString().split('T')[0];                    
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];      
        if (createdAt.includes(yesterday_date)) {
            return 'Yesterday, ' + localtime_createdAt;
        } else if (createdAt.includes(today_date)) {
            return 'Today, ' + localtime_createdAt;
        } else {
            // Get the day of a specific date:
            let d = new Date(createdAt)
            let createdAt_day = weekday[d.getDay()] + "," + " " + localtime_createdAt;
            return createdAt_day;
        }
    } 

    updateNotification() {            
        this.title = this.layoutService.notificationList[0].title  + " updated"     
        // this.new_notification = JSON.parse(`{"title": "${this.title}","description": "${this.description}","expirationAt": "${this.expirationAt}","link": "${this.link}"}`);                    
        this.new_notification = JSON.parse(`{"title": "${this.title}"}`);           
        // console.log('this.new_notification',this.new_notification);           
        // Id of the first notification
        this.notificationService.updateNotification(this.new_notification,this.layoutService.notificationList[0].id).subscribe(
            (updatednotification) => {          
                if (updatednotification != null) {   
                    // console.log('updatednotification',updatednotification);                   
                }                                             
        }), (error: any) => {
            console.error(error);
        };                              
    }

    getNotificationsByAuthor() {               
        this.notificationService.getNotificationsByAuthor(this.layoutService.user.hub_id).subscribe(
            notificationArray => {                
                this.layoutService.notificationList = notificationArray;                                                      
        }), (error: any) => {
            console.error(error);
        };      
    }  

    showDeleteDialog() {        
        this.title = this.selectedItem.title;
        this.visible_delete = true;        
    }
                      
    deletenotification() {
        this.notificationService.deleteNotification(this.selectedItem.id).subscribe(() => {
            this.getNotificationsInterval();
        }), (error: any) => {
            console.error(error);
        }; 
    }
    
    getNotificationsInterval() {
        console.log('this.layoutService.user.hub_id',this.layoutService.user.hub_id);
        if (this.layoutService.user.hub_id == 0) { return }        
        this.notificationService.getNotificationsAssignedToUser(this.layoutService.user.hub_id,this.sortorder,this.orderby,this.read_unread).subscribe(
            notificationArray => {   
                this.layoutService.numbernotifications = 0;  
                this.layoutService.readnotifications = 0;           
                this.layoutService.notificationList = notificationArray;  
                // console.log('this.notificationList',this.layoutService.notificationList);
                for (var i = 0; i < this.layoutService.notificationList.length; i++) {   
                    if (this.layoutService.notificationList[i].createdAt != null) {                                                    
                        this.layoutService.notificationList[i].date = this.getTimeNotification(this.layoutService.notificationList[i].createdAt); 
                    } else {
                        this.layoutService.notificationList[i].date = "";
                    }
                    if (this.layoutService.notificationList[i].read == false) {
                        this.layoutService.numbernotifications++;
                    } else {
                        this.layoutService.readnotifications++;
                    }                   
                }                  
        }), (error: any) => {
            console.error(error);
        };    
    }

    removeOneDayToDate(date) {
        date.setDate(date.getDate() - 1)      
        return date
    }

    convertUTCToLocalTime(utcDateStr) {        
        const utcDate = new Date(utcDateStr);              
        const localTime = utcDate.toLocaleString();
        return localTime;
    }
    
    mark_as_read() {               
        // let readstatus = "?read=false";     
        this.selectedItem.read =  true;
        let readstatus = "?read=true";
        this.ids_notifications = [];        
        this.ids_notifications.push(this.selectedItem.id);      
        this.list_ids_notifications = JSON.parse(`{"numbers": [${this.ids_notifications}]}`);
        this.notificationService.updateNotificationsReadStatus(this.layoutService.user.hub_id,readstatus,this.list_ids_notifications).subscribe(
            (updatednotification) => {          
                if (updatednotification != null) {   
                    // console.log('updatednotification',updatednotification); 
                    this.getNotificationsInterval();                  
                }                                             
        }), (error: any) => {
            console.error(error);
        }; 
    }

    create_new() {
        this.visible_create_new = false;        
        this.layoutService.numbernotifications ++;
        this.appOrigin = "HUB";       
        this.authorId = this.layoutService.user.hub_id;              
        this.assignedTo.push(this.layoutService.user.hub_id.toString());              
        this.link = "https://www.compassis.com/";       
        this.new_notification = JSON.parse(`{"title": "${this.title}","description": "${this.description}","appOrigin": "${this.appOrigin}","authorId": ${this.authorId},"link": "${this.link}","assignedTo": [${this.assignedTo}]}`);                    
        console.log('this.new_notification_from_frontend',this.new_notification);       
        this.notificationService.createNotification(this.new_notification).subscribe(
            (newnotification) => {          
                if (newnotification != null) {   
                    console.log('newnotification',newnotification);                      
                    this.getNotificationsInterval();                
                }                                             
        }), (error: any) => {
            console.error(error);
        };    
    }

    showDetails() {          
        this.notificationService.getNotification(this.selectedItem.id).subscribe(
            retnotification => {  
                // console.log('retnotification',retnotification)                              
                this.description = retnotification.description;
                if (retnotification.expirationAt != null) {                   
                    this.expirationAt_date = this.getTimeNotification(retnotification.expirationAt);                
                } else {
                    this.expirationAt_date = "";
                }
                this.createdAt_date = this.selectedItem.date;
                this.visible = true;
                this.title = this.selectedItem.title;
                this.users_assigned = retnotification.users_assigned;
                this.users_assigned_names = [];
                this.read = this.selectedItem.read;                
                for (var i = 0; i < this.users_assigned.length; i++) {   
                    this.users_assigned_names.push(this.users_assigned[i].name);
                }                
                this.visible = true;                
        }), (error: any) => {
            console.error(error);
        }; 
    }

    mark_all_as_read() {         
        let readstatus = "?read=true";
        this.ids_notifications = [];       
        for (var i = 0; i < this.layoutService.notificationList.length; i++) {              
            this.ids_notifications.push(this.layoutService.notificationList[i].id);
        }
        this.list_ids_notifications = JSON.parse(`{"numbers": [${this.ids_notifications}]}`);
        this.notificationService.updateNotificationsReadStatus(this.layoutService.user.hub_id,readstatus,this.list_ids_notifications).subscribe(
            (updatednotification) => {          
                if (updatednotification != null) {   
                    console.log('updatednotification',updatednotification);  
                    this.getNotificationsInterval();                 
                }                                             
        }), (error: any) => {
            console.error(error);
        }; 
    }

    onChangeOrder(event) {                 
        if (event == null) { return }
        // console.log('Sort order',event.value.code);
        if (event.value.code == "ASC") {
            this.sortorder = "?orderDir=ASC"           
        } else if (event.value.code == "DESC") { 
            this.sortorder = "?orderDir=DESC"            
        }        
        this.getNotificationsInterval();
    }

    onChangeOrderBy(event) {   
        if (event == null) { return }
        // console.log('Order by',event.value.code);
        if (event.value.code == "author") {
            this.orderby = "orderBy=author"            
        } else if (event.value.code == "createdAt") { 
            this.orderby = "orderBy=createdAt"           
        } else if (event.value.code == "expirationAt") { 
            this.orderby = "orderBy=expirationAt"   
        } else if (event.value.code == "title") { 
            this.orderby = "orderBy=title"   
        }
        this.getNotificationsInterval();      
    }
}

