import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];  
    email: string;

    constructor(
        public layoutService: LayoutService,
        public auth: AuthService
    ) { }

    ngOnInit() {      
        this.auth.user$.subscribe(
            (profile) => {
                this.model = [
                    {
                        label: 'Home',
                        items: [                                                         
                            { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] },                                                        
                        ]
                    },         
                    {
                        label: 'Utilities',
                        items: [       
                            { label: 'Notifications', icon: 'pi pi-fw pi-comment', routerLink: ['/components/notifications'] },                                                                                 
                            { label: 'Inspections', icon: 'pi pi-fw pi-list-check', routerLink: ['/components/forms'] },                             
                            { label: 'Delivery notes', icon: "pi pi-fw pi-tags", routerLink: ['/components/deliverynotes'] },                                              
                            { label: '3D model', icon: "pi pi-fw pi-box", url: ['https://www.compassis.com//downloads/drawp/web/drawp.html'], target: '_blank' },                           
                            { label: 'Documentation', icon: 'pi pi-fw pi-book', url: ['https://compass-bimnaval.scipedia.com/'], target: '_blank'},                                                      
                            { label: 'Diary', icon: "pi pi-fw pi-calendar", routerLink: ['/components/diary'] },                   
                        ]
                    },                               
                    {
                        label: 'Getting Started',
                        items: [
                            {
                                label: 'Support', icon: 'pi pi-fw pi-file', routerLink: ['/components/support']
                            },                  
                        ]
                    },           
                ];
                
                if (profile != null) {
                    this.email = profile.email;
                    let result = this.email.indexOf("@");
                    let res = this.email.substring(result);                
                    if (res == "@compassis.com") {
                        let compass_tools = {
                            label: 'Compass internal tools',
                            items: [ 
                                { label: 'Signatures', icon: "pi pi-fw pi-pencil", routerLink: ['/components/signatures'] }, 
                                { label: 'Ship side view', icon: "pi pi-fw pi-map", routerLink: ['/components/shipsideview'] }, 
                                { label: 'Email notification', icon: "pi pi-fw pi-pencil", routerLink: ['/components/email_notification'] }, 
                            ]
                        };   
                        this.model.push(compass_tools); 
                    } 
                }
            }
        )                  
    }
}

