import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from './user';
import { Roles } from './role';
import { Permissions } from './permission';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthClientConfig } from '@auth0/auth0-angular';

@Injectable({ providedIn: 'root' })
export class UserService {

  user: string;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  apiUri: any;
  appUri: any;

  backendUrl: string | undefined;

  constructor(
    private http: HttpClient,
    private configauth: AuthClientConfig) {
        this.appUri = this.configauth.get()?.httpInterceptor.allowedList[0];
        // Avoid '/*' in appUri
        const appUri_length = this.appUri.length;
        this.appUri = this.appUri.slice(0,appUri_length-2);
        // this.apiUri = this.configauth.get()?.authorizationParams.redirect_uri;
        this.backendUrl = `${this.appUri}/users`;
        // console.log('appUri',this.appUri);
        // console.log('apiUri',this.apiUri);
        // console.log('backend',this.backendUrl);
  }

  getAllActiveUsers(token: any): Observable<User[]> {
    return this.http.get<User[]>(this.backendUrl)
      .pipe(
        catchError(this.handleError<User[]>('getAllActiveUsers', []))
      );
  }

  getMyHubId(auth_user_id: string, accessToken: string): Observable<User> {
    // console.log('auth_user_id',auth_user_id);
    const auth0Id = "auth0Id"
    const url = `${this.backendUrl}/${auth0Id}/${auth_user_id}`;
    // console.log('url',url);   
  
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': "Bearer " + accessToken // Attach the Bearer token
      })
    };    

    return this.http.get<User>(url, httpOptions)
      .pipe(
        catchError(this.handleError<User>('getMyHubId'))
      );
  }

  getActiveUser(hub_id: number): Observable<User> {
    const HubId = "HubId";
    const url = `${this.backendUrl}/${HubId}/${hub_id}`;   
    return this.http.get<User>(url)
      .pipe(
        catchError(this.handleError<User>('getActiveUserById'))
    );
  }

  createUser(user: User): Observable<User[]> {
    return this.http.post<User[]>(this.backendUrl, user)
      .pipe(
        catchError(this.handleError<User[]>('createUser', []))
      );
  }

  getUserRoles(hub_id: number): Observable<Roles[]> {
    const roles = "roles";
    const url = `${this.backendUrl}/${roles}/${hub_id}`; 
    return this.http.get<Roles[]>(url)
      .pipe(
        catchError(this.handleError<Roles[]>('getUserRoles',[]))
      );
  }

  getUserPermissions(hub_id: number): Observable<Permissions[]> {
    const permissions = "permissions";
    const url = `${this.backendUrl}/${permissions}/${hub_id}`; 
    return this.http.get<Permissions[]>(url)
      .pipe(
        catchError(this.handleError<Permissions[]>('getUserPermissions', []))
      );
  }

  updateActiveUser(hub_id: number,profile: string): Observable<User> {
    const hubId = "hubId";
    const url = `${this.backendUrl}/${hubId}/${hub_id}`;     
    return this.http.patch<User>(url,profile)
      .pipe(
        catchError(this.handleError<User>('updateActiveUser'))
    );
  }

  uploadUserSignature(hub_id: number,png: string) {    
    const signature = "signature"; 
    const url = `${this.backendUrl}/${signature}/${hub_id}`;     
    const payload = {
      signature: png,
    };
    console.log('url',url);
    console.log('signature',payload);
    return this.http.post(url,payload)
      .pipe(
        catchError(this.handleError('uploadUserSignature'))
    );   
  }

  getUserSignature(hub_id: number): Observable<User> {
    const signature = "signature"; 
    const url = `${this.backendUrl}/${signature}/${hub_id}`; 
    return this.http.get<User>(url)
      .pipe(
        catchError(this.handleError<User>('uploadUserSignature'))
    ); 
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error('ROSA error',error); // log to console instead
      console.error('ROSA error',error); // log to console instead
      // Better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
