<!DOCTYPE html>
<html lang="en">
   <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Notification</title>
   </head>
   <body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
    <div class="grid mt-2">
        <div [ngClass]="getClass_parent()">
            <div [ngClass]="getClass()">
                <li class="flex align-items-center gap-2" *ngIf="isBellEnabled()">	                    
                    <div class="mb-2">
                        <h5>Notifications</h5>
                    </div>	
                    <button pButton [routerLink]="['/']" class="flex align-items-center gap-2 text-color-secondary ml-auto text-sm text-blue-600 p-button-text" >                                   
                        <i class="pi pi-times"></i>                                
                    </button>									 
                </li>  
                <p-tabView orientation="left" class="text-blue-600">                
                    <p-tabPanel header="All" class="line-height-3 m-0" (click)="getNotificationsInterval()">                      
                        <div *ngIf="auth.user$ | async as user">  
                            <p-toolbar>
                                <div class="p-toolbar-group-left flex flex-wrap">                 
                                    <div class="flex flex-column md:flex-row md:justify-content-between gap-2">                                   
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input type="search" pInputText placeholder="Search" (input)="dt1_all.filterGlobal($event.target.value, 'contains')">
                                        </span>	
                                        <p-dropdown [showClear]="true" [options]="orderbyList" 
                                        [(ngModel)]="selectedOrderby" optionLabel="name" (onChange)="onChangeOrderBy($event)"
                                        placeholder="Order by"/> 
                                        <p-dropdown [showClear]="true" [options]="orderList" 
                                        [(ngModel)]="selectedOrder" optionLabel="ordername" (onChange)="onChangeOrder($event)"
                                        placeholder="Sort order"/>  
                                        <button pButton label="Mark all as read" icon="pi pi-check" 
                                        (click)="mark_all_as_read()"
                                        class="gap-2 text-sm text-color-secondary p-button-text"></button>                                       
                                    </div>
                                </div>
                            </p-toolbar>
                            <div class="flex flex-column gap-3 mt-2">                                                                                                                                                                                                                                                   
                                <ul class="list-none p-0 flex flex-column">                                                                                                                                                                                                                                                                                                                                                               
                                    <p-table #dt1_all selectionMode="single" 
                                    [(selection)]="selectedItem" 
                                    [value]="layoutService.notificationList"                                                                         
                                    [globalFilterFields]="['title', 'createdAt']" 
                                    [scrollable]="true" 
                                    scrollHeight="400px"                                                                                                                                            
                                    dataKey="code">                                                                                                                                                                                         
                                        <ng-template pTemplate="body" let-rowData>
                                            <tr>                                                 
                                                <td style="width: 5%;; min-width: 5rem;">
                                                    <div *ngIf="rowData.read == false">                                                                                                                                                                   
                                                        <div class="flex flex-wrap gap-2 align-items-end">
                                                            <p-badge value="New" styleClass="ml-4"></p-badge>
                                                        </div>                                                        
                                                        <p-avatar [image]="user.picture" styleClass="mr-2 mb-3" size="small" shape="circle"></p-avatar>                                                                                                         
                                                    </div>
                                                    <div *ngIf="rowData.read == true">
                                                        <p-avatar [image]="user.picture" styleClass="mr-2 mb-3" size="small" shape="circle"></p-avatar>                                                                                                         
                                                    </div>                                                    
                                                </td>
                                                <td style="width: 90%; min-width: 5rem;">                                                   
                                                    <div>                                                        
                                                        <ng-container *ngIf="rowData.read == false">   
                                                            <a href="{{rowData.link}}" (click)="selectedItem = rowData;mark_as_read()" target="_blank" rel="noopener noreferrer">
                                                                <span class="font-medium font-bold text-blue-600">{{rowData.title}}</span>                         
                                                            </a>                                                                               
                                                        </ng-container>
                                                        <ng-container *ngIf="rowData.read == true">   
                                                            <a href="{{rowData.link}}" (click)="selectedItem = rowData;mark_as_read()" target="_blank" rel="noopener noreferrer">
                                                                <span class="font-medium text-900">{{rowData.title}}</span>                                                                                                        
                                                            </a>
                                                        </ng-container>                                                            
                                                        <div>                                               
                                                            <span class="text-sm text-color-secondary">{{rowData.date}}</span>                                                                                         
                                                        </div>  
                                                        <div>
                                                            <button type="button" pButton icon="pi pi-ellipsis-h" size="small"
                                                            class="p-button p-component p-button-text p-button-icon-only"
                                                            (click)="selectedItem = rowData;showDetails()">
                                                            </button>                                                                                                
                                                        </div> 
                                                    </div>    
                                                </td>                                               
                                                <td style="width: 5%; min-width: 5rem;">   
                                                    <button type="button" pButton icon="pi pi-trash"
                                                        class="p-button p-component p-button-text p-button-icon-only"
                                                        (click)="selectedItem = rowData;showDeleteDialog()">
                                                    </button>                                                     
                                                </td>                                             
                                            </tr>                                           
                                        </ng-template>
                                    </p-table>  
                                    <p-dialog header="Delete notification" [modal]="true" [(visible)]="visible_delete" [style]="{ width: '25rem' }">
                                        <span class="p-text-secondary block mb-5">Are you sure you want to delete notification: '{{title}}'?</span>                                 
                                        <div class="flex justify-content-end gap-2">
                                            <p-button label="No" severity="secondary" (onClick)="visible_delete = false" />
                                            <p-button label="Yes" (onClick)="visible_delete = false;deletenotification();" />
                                        </div>
                                    </p-dialog> 
                                </ul>                                                                                                                                         
                                <p-dialog header="Details" [draggable]="false" [modal]="true" [(visible)]="visible" [style]="{ width: '390px' }">                                  
                                    <div class="surface-section">   
                                        <div *ngIf="read == false" class="flex flex-wrap gap-2 align-items-end">                                                                                       
                                            <p-badge value="New" styleClass="ml-4"></p-badge>
                                        </div>                                                                 
                                        <ul class="list-none p-2 m-0">
                                            <li *ngIf="title != null" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                <div class="text-500 w-2 md:w-2 font-medium">Title</div>
                                                <div style="padding-left: 25px;" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{title}}</div>                                               
                                            </li>                                                                                                                                                                          
                                            <li *ngIf="description != null" class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                                <div class="text-500 w-2 md:w-2 font-medium">Description</div>
                                                <div style="padding-left: 25px;" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                                    {{description}}</div>                                               
                                            </li>
                                            <li *ngIf="createdAt_date != null" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                <div class="text-500 w-2 md:w-2 font-medium">Creation date</div>
                                                <div style="padding-left: 25px;" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{createdAt_date}}</div>                                               
                                            </li> 
                                            <li *ngIf="expirationAt_date != null" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                <div class="text-500 w-2 md:w-2 font-medium">Expiration date</div>
                                                <div style="padding-left: 25px;" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{expirationAt_date}}</div>                                               
                                            </li> 
                                            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                <div class="text-500 w-2 md:w-2 font-medium">Assigned to</div>
                                                <div style="padding-left: 25px;" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">                                                   
                                                    <div *ngFor="let username of users_assigned_names" >
                                                        <p-chip label={{username}} class="mr-2"></p-chip>                                                                                                        
                                                    </div>                                                   
                                                </div>                                               
                                            </li> 
                                        </ul>
                                    </div>   
                                    <div class="flex justify-content-end gap-2">                                       
                                        <button pButton pRipple type="button" icon="pi pi-times" label="Close" (click)="visible = false" class="p-button-outlined text-blue-600 p-2"></button>
                                    </div>                                                              
                                </p-dialog>                                                                                         
                            </div>                                                  
                        </div>                                                  
                    </p-tabPanel> 
                    <p-tabPanel header="Unread" class="line-height-3 m-0" (click)="getNotificationsInterval()">                                             
                            <div *ngIf="auth.user$ | async as user"> 
                                <p-toolbar>
                                    <div class="p-toolbar-group-left flex flex-wrap">   
                                        <div class="flex flex-column md:flex-row md:justify-content-between gap-2">                             
                                            <span class="p-input-icon-left">
                                                <i class="pi pi-search"></i>
                                                <input type="search" pInputText placeholder="Search" (input)="dt1_unread.filterGlobal($event.target.value, 'contains')">
                                            </span>	
                                            <p-dropdown [showClear]="true" [options]="orderbyList" 
                                            [(ngModel)]="selectedOrderby" optionLabel="name" (onChange)="onChangeOrderBy($event)"
                                            placeholder="Order by"/> 
                                            <p-dropdown [showClear]="true" [options]="orderList" 
                                            [(ngModel)]="selectedOrder" optionLabel="ordername" (onChange)="onChangeOrder($event)"
                                            placeholder="Sort order"/>  
                                            <button pButton label="Mark all as read" icon="pi pi-check" 
                                            (click)="mark_all_as_read()"
                                            class="gap-2 text-sm text-color-secondary p-button-text"></button>                                       
                                        </div> 
                                    </div>
                                </p-toolbar>      
                                <div class="flex flex-column gap-3">                                                                                                                                                                                                                                             
                                <ul class="list-none p-0 flex flex-column">                                                                                                                                                                                                                                                                                                                                                               
                                    <p-table #dt1_unread 
                                    selectionMode="single" 
                                    [(selection)]="selectedItem" 
                                    [value]="layoutService.notificationList"                                                                         
                                    [globalFilterFields]="['title', 'createdAt']"
                                    [scrollable]="true" 
                                    scrollHeight="400px" 
                                    [tableStyle]="{'min-width': '10rem'}"                                   
                                    dataKey="code">                                                                                                                                                                                         
                                        <ng-template pTemplate="body" let-rowData>
                                            <tr *ngIf="rowData.read == false">                                                                                                
                                                <td style="width: 5%; min-width: 5rem;">                                                                                                                                                                                                                    
                                                    <div class="flex flex-wrap gap-2 align-items-end">
                                                        <p-badge value="New" styleClass="ml-4"></p-badge>
                                                    </div>                                                        
                                                    <p-avatar [image]="user.picture" styleClass="mr-2 mb-3" size="small" shape="circle"></p-avatar>                                                                                                                                                                                                                                                                
                                                </td>                                                
                                                                                                    
                                                <td style="width: 85%; min-width: 5rem;">                                                                                                       
                                                        <ng-container>   
                                                            <a href="{{rowData.link}}" (click)="selectedItem = rowData;mark_as_read()" target="_blank" rel="noopener noreferrer">
                                                                <span class="font-medium font-bold text-blue-600">{{rowData.title}}</span>                         
                                                            </a>                                                                               
                                                        </ng-container>                                                                                                            
                                                        <div>                                               
                                                            <span class="text-sm text-color-secondary">{{rowData.date}}</span>                                                                                         
                                                        </div>  
                                                        <div>
                                                            <button type="button" pButton icon="pi pi-ellipsis-h" size="small"
                                                            class="p-button p-component p-button-text p-button-icon-only"
                                                            (click)="selectedItem = rowData;showDetails()">
                                                            </button>                                                                                                
                                                        </div> 
                                                </td>                                                                                             
                                                <td style="width: 5%; min-width: 5rem;">   
                                                    <button type="button" pButton icon="pi pi-trash"
                                                        class="p-button p-component p-button-text p-button-icon-only"
                                                        (click)="selectedItem = rowData;showDeleteDialog()">
                                                    </button> 
                                                </td>                                             
                                            </tr>                                           
                                        </ng-template>
                                    </p-table>  
                                    <p-dialog header="Delete notification" [modal]="true" [(visible)]="visible_delete" [style]="{ width: '25rem' }">
                                        <span class="p-text-secondary block mb-5">Are you sure you want to delete notification: '{{title}}'?</span>                                 
                                        <div class="flex justify-content-end gap-2">
                                            <p-button label="No" severity="secondary" (onClick)="visible_delete = false" />
                                            <p-button label="Yes" (onClick)="visible_delete = false;deletenotification();" />
                                        </div>
                                    </p-dialog>                                                                                                    
                                </ul>                                 
                                <p-dialog header="Details" [draggable]="false" [modal]="true" [(visible)]="visible" [style]="{ width: '390px' }">                                  
                                    <div class="surface-section">   
                                        <div *ngIf="read == false" class="flex flex-wrap gap-2 align-items-end">                                                                                       
                                            <p-badge value="New" styleClass="ml-4"></p-badge>
                                        </div>                           
                                        <ul class="list-none p-2 m-0">
                                            <li *ngIf="title != null" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                <div class="text-500 w-2 md:w-2 font-medium">Title</div>
                                                <div style="padding-left: 25px;" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{title}}</div>                                               
                                            </li>                                                                                                                                                                          
                                            <li *ngIf="description != null" class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                                <div class="text-500 w-2 md:w-2 font-medium">Description</div>
                                                <div style="padding-left: 25px;" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                                    {{description}}</div>                                               
                                            </li>
                                            <li *ngIf="createdAt_date != null" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                <div class="text-500 w-2 md:w-2 font-medium">Creation date</div>
                                                <div style="padding-left: 25px;" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{createdAt_date}}</div>                                               
                                            </li> 
                                            <li *ngIf="expirationAt_date != null" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                <div class="text-500 w-2 md:w-2 font-medium">Expiration date</div>
                                                <div style="padding-left: 25px;" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{expirationAt_date}}</div>                                               
                                            </li> 
                                            <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                                <div class="text-500 w-2 md:w-2 font-medium">Assigned to</div>
                                                <div style="padding-left: 25px;" class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">                                                   
                                                    <div *ngFor="let username of users_assigned_names" >
                                                        <p-chip label={{username}} class="mr-2"></p-chip>                                                                                                        
                                                    </div>                                                   
                                                </div>                                               
                                            </li> 
                                        </ul>
                                    </div>   
                                    <div class="flex justify-content-end gap-2">                                       
                                        <button pButton pRipple type="button" icon="pi pi-times" label="Close" (click)="visible = false" class="p-button-outlined text-blue-600 p-2"></button>
                                    </div>                                                              
                                </p-dialog>    
                            </div>                                                  
                        </div>                                                  
                    </p-tabPanel>                                                                                   
                    <!-- <p-tabPanel *ngIf="user_from_compass" header="Compass tools" class="line-height-3 m-0">
                        <div class="flex flex-column gap-3 w-25rem">
                            <div *ngIf="auth.user$ | async as user">
                                <div class="flex flex-wrap gap-2">                               
                                    <button pButton pRipple type="button" icon="pi pi-plus"  label="Create new" (click)="visible_create_new =  true" class="p-button-outlined"></button>                              
                                    <button pButton pRipple type="button" class="p-2" icon="pi pi-pencil"  label="Update welcome" (click)="updateNotification()" class="p-button-outlined"></button>                                                                
                                </div>                                                           
                            </div>     
                        </div>
                    </p-tabPanel>   -->
                    <p-dialog header="Create new notification" [draggable]="false" [modal]="true" [(visible)]="visible_create_new" 
                    [style]="{ width: '390px' }">                                                         
                        <div class="flex align-items-center gap-3 mb-3">
                            <label for="username" class="font-semibold w-6rem">title</label>
                            <input pInputText id="title" [(ngModel)]="title" class="flex-auto" autocomplete="off"/>
                        </div> 
                        <div class="flex align-items-center gap-3 mb-3">
                            <label for="username" class="font-semibold w-6rem">description</label>
                            <input pInputText id="description" [(ngModel)]="description" class="flex-auto" autocomplete="off"/>
                        </div> 
                        <div class="flex justify-content-end gap-2">                                       
                            <button pButton pRipple type="button" icon="pi pi-times" label="Ok" (click)="create_new()" class="p-button-outlined text-blue-600 p-2"></button>
                            <button pButton pRipple type="button" icon="pi pi-times" label="Cancel" (click)="visible_create_new = false" class="p-button-outlined text-blue-600 p-2"></button>                            
                        </div>                                                              
                    </p-dialog>                                                                       
                </p-tabView> 
            </div>
        </div> 
    </div>                          
   </body>
</html>



