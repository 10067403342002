import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Notification } from './notification';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthClientConfig } from '@auth0/auth0-angular';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  notification: string; 

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  apiUri: any;
  appUri: any;

  backendUrl: string | undefined; 

  constructor(
    private http: HttpClient,
    private configauth: AuthClientConfig) {      
      this.appUri = this.configauth.get()?.httpInterceptor.allowedList[0];
      // Avoid '/*' in appUri
      const appUri_length = this.appUri.length;
      this.appUri = this.appUri.slice(0,appUri_length-2); 
      // this.apiUri = this.configauth.get()?.authorizationParams.redirect_uri;
      this.backendUrl = `${this.appUri}/notifications`;     
      // console.log('appUri',this.appUri);
      // console.log('apiUri',this.apiUri);   
      // console.log('backend',this.backendUrl);     
  }

  /** GET notifications from the server */
  getNotifications(sortorder: string, orderby: string): Observable<Notification[]> {  
      let and = ''
      if(sortorder != "" && orderby != "") {
        and = "&"
      }         
      const url = `${this.backendUrl}${sortorder}${and}${orderby}`;         
      return this.http.get<Notification[]>(url)
      .pipe(       
        catchError(this.handleError<Notification[]>('getNotifications', []))
      );
  }

    /** GET notifications assigned to user from the server */
  getNotificationsAssignedToUser(hub_id: number, sortorder: string, orderby: string, read_unread: string): Observable<Notification[]> {  
    let and = ''
    if(sortorder != "" && orderby != "") {
      and = "&"
    }         
    const assignedtouser = "assigned";
    const url = `${this.backendUrl}/${assignedtouser}/${hub_id}${read_unread}${sortorder}${and}${orderby}`;        
    return this.http.get<Notification[]>(url)
    .pipe(       
      catchError(this.handleError<Notification[]>('getNotificationsAssignedToUser', []))
    );
  }

  /** GET notification from the server */
  getNotification(id: number): Observable<Notification> {   
    const url = `${this.backendUrl}/${id}`;   
    return this.http.get<Notification>(url)
    .pipe(       
      catchError(this.handleError<Notification>('getNotification'))
    );
  }

  /** DELETE notification on the server */
  deleteNotification(id: number): Observable<unknown> {
    const url = `${this.backendUrl}/${id}`;
    return this.http.delete(url)
      .pipe (
        catchError(this.handleError('deleteNotification',[]))
      );
  }

  /** CREATE a notification on the server and add it to the database*/
  createNotification(new_notification: string): Observable<Notification[]> {
    // console.log('new_notification',new_notification);
    return this.http.post<Notification[]>(this.backendUrl,new_notification)
      .pipe(
        catchError(this.handleError<Notification[]>('createNotification', []))
      );
  }  

  /** GET notifications by author */
  getNotificationsByAuthor(id: number): Observable<Notification[]> {  
      const author = "author"
      const url = `${this.backendUrl}/${author}/${id}`;        
      return this.http.get<Notification[]>(url)
      .pipe(       
        catchError(this.handleError<Notification[]>('getNotificationsByAuthor', []))
      );
  }

  /** UPDATE a notification on the server */
  updateNotification(new_notification: string,id: number): Observable<Notification[]> {
    const url = `${this.backendUrl}/${id}`;        
    return this.http.patch<Notification[]>(url,new_notification)
      .pipe(
        catchError(this.handleError<Notification[]>('updateNotification', []))
      );
  }  

   /** UPDATE a notification status on the server */
  updateNotificationsReadStatus(hub_id: number,readstatus: string,list_ids_notifications: string): Observable<Notification[]> {
    const namereadstatus = "readStatus";   
    const url = `${this.backendUrl}/${namereadstatus}/${hub_id}${readstatus}`;      
    return this.http.patch<Notification[]>(url,list_ids_notifications)
      .pipe(
        catchError(this.handleError<Notification[]>('updateNotificationsReadStatus', []))
      );
  }  

  /**
   * Handle Http operation that failed.
   * Let the app continue.   
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {      
      console.error(error); // log to console instead

      // Better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
