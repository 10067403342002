import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Project } from './project';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthClientConfig } from '@auth0/auth0-angular';

@Injectable({ providedIn: 'root' })
export class ProjectService {

  project: string; 

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  apiUri: any;
  appUri: any;

  backendUrl: string | undefined; 

  constructor(
    private http: HttpClient,
    private configauth: AuthClientConfig) {      
      this.appUri = this.configauth.get()?.httpInterceptor.allowedList[0];
      // Avoid '/*' in appUri
      const appUri_length = this.appUri.length;
      this.appUri = this.appUri.slice(0,appUri_length-2); 
      // this.apiUri = this.configauth.get()?.authorizationParams.redirect_uri;
      this.backendUrl = `${this.appUri}/projects`;     
      // console.log('appUri',this.appUri);
      // console.log('apiUri',this.apiUri);   
      // console.log('backend',this.backendUrl);     
  }

  reopenProject(id: number): Observable<Project> {
    const reopen = "reopen";
    const url = `${this.backendUrl}/${reopen}/${id}`; 
    // console.log('url',url);    
    return this.http.patch<Project>(url,null)
      .pipe(
        catchError(this.handleError<Project>('reopenProject'))
      );
  }

  /** GET projects from the server */
  getProjects(orderBy: string, finished: string, orderDir: string): Observable<Project[]> {      
      let and1 = '';
      let and2 = '';
      if(finished != '' && orderBy != '') {
        and1 = "&"
      }  
      if(finished != '' && orderBy != '' && orderDir != '') {
        and2 = "&";
      }  
      if(finished != '' && orderBy != '' && orderDir != '') {
        and2 = "&";
      }
      const url = `${this.backendUrl}${finished}${and1}${orderBy}${and2}${orderDir}`;         
      // console.log('url',url);
      return this.http.get<Project[]>(url)
      .pipe(       
        catchError(this.handleError<Project[]>('getProjects', []))
      );
  }

  /** GET project from the server */
  getProject(id: number): Observable<Project> {
    const url = `${this.backendUrl}/${id}`;         
    // console.log('url',url);
    return this.http.get<Project>(url)
    .pipe(       
      catchError(this.handleError<Project>('getProject'))
    );
  }

  getProjectsByManager(hub_id: number, orderBy: string, finished: string, orderDir: string): Observable<Project[]> {      
    let and1 = '';
    let and2 = '';
    if(finished != '' && orderBy != '') {
      and1 = "&"
    }  
    if(finished != '' && orderBy != '' && orderDir != '') {
      and2 = "&";
    }    
    if(finished != '' && orderDir != '') {
      and2 = "&";
    }    
    
    const manager = "manager"; 
    const url = `${this.backendUrl}/${manager}/${hub_id}/${finished}${and1}${orderBy}${and2}${orderDir}`;         
    // console.log('url',url);
    return this.http.get<Project[]>(url)
    .pipe(       
      catchError(this.handleError<Project[]>('getProjectsByManager', []))
    );
}

  // /** DELETE a project on the server */
    deleteProject(id: number): Observable<unknown> {
      const url = `${this.backendUrl}/${id}`;
      return this.http.delete(url)
        .pipe (
          catchError(this.handleError('deleteProject',[]))
        );
    }

  // /** CREATE a project on the server and add it to the database*/
    createProject(new_project: string): Observable<Project> {
    return this.http.post<Project>(this.backendUrl,new_project)
      .pipe(
        catchError(this.handleError<Project>('createProject'))
      );
  }  

  // /** FINISH a project on the server */
  finishProject(id: number): Observable<Project> {
    const finish = "finish";
    const url = `${this.backendUrl}/${finish}/${id}`; 
    // console.log('url',url);    
    return this.http.patch<Project>(url,null)
      .pipe(
        catchError(this.handleError<Project>('finishProject'))
      );
  }

  /** UPDATE a project on the server */
  updateProject(id: number,update_project: string): Observable<Project> {
    const url = `${this.backendUrl}/${id}`;        
    return this.http.patch<Project>(url,update_project)
      .pipe(
        catchError(this.handleError<Project>('updateProject'))
      );
  }   

  /**
   * Handle Http operation that failed.
   * Let the app continue.   
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {      
      console.error(error); // log to console instead

      // Better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
